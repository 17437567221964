import * as React from 'react';
import Function from './Function';

class Home extends React.PureComponent<any, { assembly: string, classname: string }> {

    constructor(props: any) {
        super(props);

        this.state = {
            assembly: "",
            classname: ""
        }
    }

    checkHandle(val: any) { }

    render() {
        return (
            <Function checkHandle={this.checkHandle.bind(this)} />
        )
    }
}

export default Home
