import * as React from 'react';
import './SignIn.css'
import { FaUserAlt, FaLock } from "react-icons/fa";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Authentication from '../store/Authentication';
import * as Models from '../models/Authentication';
import { Redirect } from "react-router";
import SweetAlert from 'react-bootstrap-sweetalert';
import './Loading.css';


type AuthenticationProps =
    Models.AuthenticationState
    & typeof Authentication.actionCreators
    & AuthenticationBody;

export interface AuthenticationBody {
    username: string,
    password: string,
    isHideAlert: boolean
}

class SignIn extends React.PureComponent<AuthenticationProps, AuthenticationBody> {

    constructor(props: any) {
        super(props);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            username: "",
            password: "",
            isHideAlert: false
        };
    }

    handleSubmit(e: any) {
        var { username, password } = this.state;
        this.props.requestAuthentication({ username: username, password: password });

        this.setState({
            isHideAlert: true
        });

        e.preventDefault();
    }

    handleUsernameChange(e: any) {
        this.setState({
            username: e.target.value
        })
    }

    handlePasswordChange(e: any) {
        this.setState({
            password: e.target.value
        })
    }

    public render() {

        var { isHideAlert } = this.state;

        if (!this.props.isLoading) {
            if (this.props.response != undefined) {
                if (this.props.response!.message != "success") {
                    //this.setState({ isHideAlert: true });
                    this.props.response!.message = "success"

                    return (<Redirect to="/" />)
                }
            }
        }

        if (localStorage.getItem('FOCUSONE_API_GATEWAY')) {
            localStorage.removeItem('FOCUSONE_API_GATEWAY');
        }


        return (
            <div>
                <div style={{ position: "absolute", zIndex:20 }}>
                    {isHideAlert &&
                        this.loading()
                    }
                </div>
             <div className="container center">
                <div className="center">
                    <div className="card">
                        <div className="card-body">
                            <form className="form-horizontal" onSubmit={this.handleSubmit}>

                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><FaUserAlt /></div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Username" onChange={this.handleUsernameChange}></input>
                                </div>

                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><FaLock /></div>
                                    </div>
                                    <input type="password" className="form-control" id="inlineFormInputGroup" placeholder="Password" onChange={this.handlePasswordChange}></input>
                                </div>

                                <div>
                                    <button type="submit" className="btn btn-info btn-block">sign in</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
               </div>
          </div>
        )
    }

    loading() {
        return (
            <div style={{ background: "rgba(176, 176, 176, 0.76)", width: "100vw", height: "100vh", position: "relative" }}>
                <div className="center">
                    <div className="arc"></div>
                </div>
            </div>
            );
    }

    onConfirm(response: any) {
        this.setState({
            isHideAlert: false
        });
    }
}

export default connect(
    (state: ApplicationState) => state.authentication,
    Authentication.actionCreators
)(SignIn as any);


//return (
//    <div className="container center">

//        <div className="arc"></div>

//        {!this.props.isLoading &&
//            <SweetAlert error title="Invalid Username or Password" show={this.state.isHideAlert} onConfirm={this.onConfirm} customButtons={
//                <React.Fragment>
//                    <button className="btn btn-info" onClick={this.onConfirm}><b>Confirm</b></button>
//                </React.Fragment>
//            } />
//        }
//        <div className="center">
//            <div className="card">
//                <div className="card-body">
//                    <form className="form-horizontal" onSubmit={this.handleSubmit}>

//                        <div className="input-group mb-2">
//                            <div className="input-group-prepend">
//                                <div className="input-group-text"><FaUserAlt /></div>
//                            </div>
//                            <input type="text" className="form-control" placeholder="Username" onChange={this.handleUsernameChange}></input>
//                        </div>

//                        <div className="input-group mb-2">
//                            <div className="input-group-prepend">
//                                <div className="input-group-text"><FaLock /></div>
//                            </div>
//                            <input type="password" className="form-control" id="inlineFormInputGroup" placeholder="Password" onChange={this.handlePasswordChange}></input>
//                        </div>

//                        <div>
//                            <button type="submit" className="btn btn-info btn-block">sign in</button>
//                        </div>
//                    </form>
//                </div>
//            </div>
//        </div>
//    </div>
//);