import * as React from 'react';
import './Function.css'
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Stores from '../store/Function';
import * as Models from '../models/Function';
import * as Mapping from '../models/MappingFunctionData';
import { Spinner } from 'reactstrap';
import ReactJson from 'react-json-view'
import SearchResults from 'react-filter-search';
import { FaClipboard } from "react-icons/fa";
import copy from "copy-to-clipboard";
import CopyToClipboard from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import 'react-toastify/dist/ReactToastify.css';

type Store = Models.FunctionState & typeof Stores.actionCreators;

class Function extends React.Component<any, { value: string }, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: ''
        }

        //this.OnClickCopy = this.OnClickCopy.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        const query = new URLSearchParams(window.location.search);
        let assembly = query.get('assembly');
        let classname = query.get('classname');

        if (assembly && classname) {
            this.props.requestFunction(assembly, classname);
        }
    }

    private OnClickCopy(val: any) {
        copy(JSON.stringify(val, null, 2));
        toast.success('copy to clipboard', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    render() {

        var _prepare = this.prepareData(this.props.model);
        var isShowAlert = this.props.isShowAlert;
        var value = this.state.value;

        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-md-12 mt-2">
                        {this.props.isLoading &&
                            <div>
                                <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />
                            </div>
                        }
                        {!this.props.isLoading

                            //<ReactJson src={this.props.model} theme="monokai" collapsed={4} name={false}/>
                        }

                        {(this.props.model != undefined) &&
                            <div>
                                {(isShowAlert) ? (
                                    <SweetAlert error title={this.props.errorMessage} show={isShowAlert} onConfirm={() => this.onClose(this.props.errorMessage)} customButtons={
                                        <React.Fragment>
                                            <button className="btn btn-info" onClick={() => this.onClose(this.props.errorMessage)}><b>Close</b></button>
                                        </React.Fragment>
                                    } />
                                ) : (
                                        <div>
                                            <h2 style={{ fontSize: "14pt" }}>{this.props.model.class_name}</h2>
                                            {_prepare.length > 0 &&
                                                <div className='control' style={{ minWidth: "200px" }}>
                                                    <input style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }} placeholder='Filter by' type='text' onChange={this.handleChange} />
                                                </div>
                                            }
                                            <Tabs>
                                                <TabList>
                                                    <Tab>Version 1</Tab>
                                                    <Tab>Version 2</Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <div>
                                                        <SearchResults
                                                            value={value}
                                                            data={_prepare}
                                                            renderResults={(results: any) => (
                                                                <React.Fragment>
                                                                    <div className='function-list'>
                                                                        {results.map((namespace: Mapping.Main, index: any) =>
                                                                            <div className="pt-2">
                                                                                <div className="pb-2">
                                                                                    <h2 className="d-inline" style={{ fontSize: "10pt", marginTop: "1em", fontWeight: 800 }}>{namespace.function_name}</h2>
                                                                                    <button className="btn btn-success btn-sm d-inkline ml-1" onClick={this.OnClickCopy.bind(this, namespace.clip_board)}><FaClipboard /></button>
                                                                                </div>
                                                                                <ReactJson src={namespace.function} theme="monokai" collapsed={0} name={false} />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </div>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div>
                                                        <SearchResults
                                                            value={value}
                                                            data={_prepare}
                                                            renderResults={(results: any) => (
                                                                <React.Fragment>
                                                                    <div className='function-list'>
                                                                        {results.map((namespace: Mapping.Main, index: any) =>
                                                                            <div className="pt-2">
                                                                                <div className="pb-2">
                                                                                    <h2 className="d-inline" style={{ fontSize: "10pt", marginTop: "1em", fontWeight: 800 }}>{namespace.function_name}</h2>
                                                                                    <button className="btn btn-success btn-sm d-inkline ml-1" onClick={this.OnClickCopy.bind(this, namespace.clip_board_v2)}><FaClipboard /></button>
                                                                                </div>
                                                                                <ReactJson src={namespace.function_v2} theme="monokai" collapsed={0} name={false} />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    )
                                }

                            </div>
                        }

                        <ToastContainer />
                    </div>
                </div>

            </React.Fragment>
        );
    }

    onClose(errorMessage: any) {
        this.props.clearAlert();

        if (errorMessage === 'token expired') {
            window.location.href = "/logout";
        }
    }

    handleChange = (event: any) => {
        const { value } = event.target;
        this.setState({ value });
    };

    prepareData(val: any) {
        var _MAIN: Array<Mapping.Main> = [];

        try {
            var bVal = JSON.stringify(val);
            var val2 = JSON.parse(bVal);
            for (var i = 0; i < val.function_list.length; i++) {
                this.prepareFunctionV2(val2.function_list[i]);

                var _clipBoard: Mapping.ClipBoard = { class_name: val.class_name, function_name: val.function_list[i].function_name, args: val.function_list[i].args };
                var _clipBoard2: Mapping.ClipBoard = { class_name: val2.class_name, function_name: val2.function_list[i].function_name, args: val2.function_list[i].args };
                var _SUB: Mapping.Main = { function_name: val.function_list[i].function_name, function: val.function_list[i], clip_board: _clipBoard, function_v2: val2.function_list[i], clip_board_v2: _clipBoard2 };

                _MAIN.push(_SUB);
            }

        } catch {
            //value is null or Empty
        }

        return (_MAIN);
    }

    prepareFunctionV2(val: any) {
        try {
            var args = val.args
            for (var j = 0; j < args.length; j++) {
                if (args[j].hasOwnProperty('json_value')) {
                    this.prepareBean(args[j].json_value)
                }
            }
        }
        catch
        {
            //value is null or Empty
        }
    }

    prepareBean(jsonValue: any) {
        try {
            var array = Object.getOwnPropertyNames(jsonValue)
            for (var k = 0; k < array.length; k++) {
                var bean = jsonValue[array[k]];
                if (Array.isArray(bean)) {
                    var newDataTable = {
                        row_state: "modified",
                        old: null,
                        new: null
                    };

                    for (var i = 0; i < bean.length; i++) {
                        newDataTable.old = bean[i]
                        newDataTable.new = bean[i]
                        bean[i] = newDataTable
                    }
                }
                else {
                    this.prepareBean(bean)
                }
            }
        }
        catch
        {
            //value is null or Empty
        }
    }
}

export default connect((state: ApplicationState) => state.function,
    Stores.actionCreators
)(Function);