import * as React from 'react';
import './SupportedFunction.css';
import './SideBarMenu.css';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as Stores from '../store/SupportedFunction';
import * as Models from '../models/SupportedFunction';
import * as ModelMenu from '../models/SideMenu';
import Tree from '@naisutech/react-tree';
import SearchResults from 'react-filter-search';
import SweetAlert from 'react-bootstrap-sweetalert';

type Store = Models.SupportedFunctionState & typeof Stores.actionCreators;

class SupportedFunction extends React.Component<any, { isOpen: boolean, items: [], value: string }, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            items: [],
            value: ''
        }

        this.onSelect = this.onSelect.bind(this)
        this.handleSidebar = this.handleSidebar.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.requestSupportedFunction();
    }

    render() {
        if (this.props.isLoading) {
            return (
                <ul>
                    <Spinner color="primary" style={{ width: '2rem', height: '2rem', marginTop: '2rem' }} />{' '}
                </ul>
            );
        } else {
            var sidebarClass = this.state.isOpen ? "sidebar-open" : "sidebar-close";

            var data = this.prepareData(this.props.model);
            var isShowAlert = this.props.isShowAlert;
            var value = this.state.value;

            return (               
                <React.Fragment>
                    <div>
                        <div className="sidebar-flex" style={{ backgroundColor: "white" }}>
                            <div className={sidebarClass}>
                                <div className='control'>
                                    <input style={{ width: "100%" }} placeholder='Filter by' type='text' onChange={this.handleChange} />
                                </div>
                                <SearchResults
                                    value={value}
                                    data={data}
                                    renderResults={(results: any) => (
                                        <React.Fragment>
                                            <div className='tree'>
                                                <Tree nodes={results} onSelect={this.onSelect} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                />
                            </div>

                            <button onClick={this.handleSidebar}>
                                {this.state.isOpen ? '<' : '>'}
                            </button>
                        </div>
                    </div>

                    {(isShowAlert == true) &&
                        <div>
                        <SweetAlert error title={this.props.errorMessage} show={isShowAlert} onConfirm={this.onClose} customButtons={
                            <React.Fragment>
                                <button className="btn btn-info" onClick={this.onClose}><b>Close</b></button>
                            </React.Fragment>
                        } />
                        </div>
                    }
                </React.Fragment>
            );
        }
    }

    onClose(response: any) {
        this.props.clearAlert();
    }

    handleSidebar() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleChange = (event: any) => {
        const { value } = event.target;
        this.setState({ value });
    };

    prepareData(val: any) {
        var subPrepare: Array<ModelMenu.subMenu> = [];

        for (var i = 0; i < val.length; i++) {

            var subList: any = [];
            var parent = this.getRandomInt(1000000);
            var subId = this.getRandomInt(1000000);

            for (var x = 0; x < val[i].class_list.length; x++) {
                var child: ModelMenu.children = { label: val[i].class_list[x], id: subId, parentId: parent, mainNode: val[i].dll_name };
                subList.push(child);
            }

            var subData: ModelMenu.subMenu = { items: subList, label: val[i].dll_name, id: parent, parentId: null };
            subPrepare.push(subData);
        }

        return subPrepare;
    }

    getRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    onSelect(val: any) {
        if (val.mainNode) {
            var link = `/?assembly=${val.mainNode}&classname=${val.label}`;
            this.props.handlePage(link);
        }

    }
}

export default connect(
    (state: ApplicationState) => state.supportedFunction,
    Stores.actionCreators
)(SupportedFunction);