import * as React  from 'react';
import { Route, Switch } from 'react-router';
import SignIn from './SignIn';
import SubLayout from './SubLayout';
import { PrivateRoute } from './PrivateRoute';
import Home from './Home';
import SignOut from './SignOut';

export default (props: { children?: React.ReactNode }) => (

    <React.Fragment>          
        <Switch>
            <Route path='/login' component={SignIn} />  
            <SubLayout>
                <PrivateRoute exact path='/' component={Home} />
                <PrivateRoute path='/function' component={Function} />
                <PrivateRoute path='/logout' component={SignOut} />
            </SubLayout>
        </Switch>
        
    </React.Fragment>
);
