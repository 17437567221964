import * as React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import { PrivateRoute } from './components/PrivateRoute';
import Layout from './components/Layout';
import Home from './components/Home';
import Function from './components/Function';
import SignOut from './components/SignOut';
import SubLayout from './components/SubLayout';

import './custom.css'

export default () => (
    <React.Fragment>

        <Layout>         
        </Layout>

    </React.Fragment>
);
//<Redirect from="*" to="/" />
    //<PrivateRoute exact path='/' component={Home} />